ion-card.product {
    //single product flash
    margin: 0;
    cursor: pointer;
    margin-bottom: 5px;

    ion-card-content {
        padding: 0;
        background: white;
    }

    h2 {
        text-overflow: ellipsis;
        text-align: center;
        padding: 0 10px 0;
        font-family: var(--secondary-font-family);
        font-size: 14px;
        line-height: 1.5em;
        height: 3em;
        overflow: hidden;
        letter-spacing: 1px;
        color: var(--ion-color-dark-tint);
        margin: 0 0 14px;
        font-weight: 600;
    }

    .sale-info {
        line-height: 1.3;
        padding: 0 10px 10px;
        display: flex;
        justify-content: space-between;
        font-family: var(--primary-font-family);

        .sold {
            color: var(--ion-color-dark-tint);
        }

        .price {
            font-size: 16px;
            font-weight: 700;
            margin-left: 5px;
        }
    }

}
