#single-product {

    .swiper-button-next,
    .swiper-button-prev {
        background: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        &::after {
            font-size: 15px;
            width: 15px;
            height: 15px;
            color: var(--ion-color-link);
        }
    }
    .swiper-slide{
        padding: 5px 5px 0;
    }
    .swiper-slide-thumb-active{
        border: 2px solid;
    }

    .swiper-slide {
        text-align: center;
        background: var(--ion-color-light-shad);

        a {
            display: block;
        }
    }

    .title {
        color: var(--ion-color-link);
        margin-top: 0;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0px;

    }

    .sku-props {
        cursor: pointer;
        padding: 0 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        display: inline-block;
        border-radius: 5px;
        font-size: 12px;
        background: var(--ion-color-light);

        &:hover,
        &.active {
            box-shadow: 0px 1px 1px 1px;
        }

        img {
            margin-top: 5px;
        }
    }

    .left-colum {
        ion-card {
            margin: 0 0 5px;
        }
    }
}