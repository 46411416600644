.home-slider{
    ion-col{
        > div{
            height: 100%;
        }
        .mini-slider{
            img{
                object-fit: cover;
                width: 100%;
            }
        }

        height: 32vw;
        @media only screen and (max-width: 770px) {
            height: 37vw;
        }
        @media only screen and (max-width: 500px) {
            height: 63vw;
        }
    }
}
