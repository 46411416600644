ion-item.product {
    height: 100px;
    cursor: pointer;

    img {
        margin: 0 10px 0 0;
        width: 100px;
        height: 100px;
    }

    .pricing {
        width: 55px;
        padding: 0;
        margin: 0 0 0 10px;
        text-align: right;

        .regular {
            text-decoration: line-through;
            @media (prefers-color-scheme: dark) {
                color: var(--ion-color-light-shade);
            }
        }

        .sale {
            font-size: 16px;
            font-weight: 700;
            margin-left: 5px;
            @media (prefers-color-scheme: dark) {
                color: var(--ion-color-light);
            }
        }
    }

    .name {
        width: 500px;
    }
}
