#search-bar-container {
    position: relative;

    .result {
        display: none;
    }
    .spinner{
        padding: 10px;
        text-align: center;
    }
    &:hover {

        .result {
            display: inherit;
            position: absolute;
            background: var(--ion-color-light);
            width: 100%;
            z-index: 3;
            max-height: 400px;
            overflow-y: auto;
        }
    }
}
