.radio-box{
    background: var(--ion-item-background, var(--ion-background-color, #fff));
    padding: 20px;
    display: flex;
    justify-content: space-between;
    ion-radio-group{
        display: flex;
        align-items: center;
        justify-content: center;
        ion-radio{
            margin-left: 30px;
            margin-right: 10px;
        }
    }
}
