#category-sidebar {
    position: relative;

    height: 32vw;

    @media only screen and (max-width: 770px) {
        height: 37vw;
    }

    @media only screen and (max-width: 500px) {
        height: 63vw;
    }

    .swiper-wrapper {
        height: 32vw;

        @media only screen and (max-width: 770px) {
            height: 37vw;
        }

        @media only screen and (max-width: 500px) {
            height: 63vw;
        }
    }

    .sub-categories {
        background: var(--ion-color-light);
        width: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: -1;
        transition: 0.5s width, 0.5s opacity;

        &.show {
            width: 300px;
            left: 100%;
            opacity: 1;
            z-index: 2;
        }
    }
}