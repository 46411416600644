#profile{
    .cover{
        text-align: center;
        img{
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
            box-shadow: 0 3px 5px 0 #000;
            border: 2px solid white;
        }
        ion-button{
            position: absolute;
        }
    }
    .info{
        padding-top: 20px;
        .label{
            display: flex;
            align-items: center;
        }
        ion-icon {
            font-size: 20px;
            width: 28px;
            margin-right: 10px;
            line-height: 1;
        }
        ion-label{
            width: 60px;
        }
    }
}
