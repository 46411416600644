header {
    &.sticky {
        position: fixed;
        top: -200px;
        transition: 0.5s top;
        z-index: 3;
        width: 100%;
        background: #ECECEC;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.75);
        &.show {
            top: 0;
        }
    }

    .search-bar {
        --inner-padding-end: 5px;
    }

    .buttons {
        text-align: right;
        margin-top: 15px;
    }

    .desktop {
        ion-col {
            padding: 0;
        }
    }

    .mobile {
        display: none;

        @media only screen and (max-width: 576px) {
            display: block;
        }

        .brand {
            justify-content: center;
        }

        .search-bar {
            margin-top: 0;
        }
    }

    .tab {

        @media only screen and (max-width: 576px) {
            display: none;
        }

        @media only screen and (min-width: 769px) {
            display: none;
        }
    }

    .desktop {
        .search-bar {
            margin-top: 15px;
        }

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
}
