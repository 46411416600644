#contact-page {
    img {
        width: 100%;
        height: auto;
    }
    a{
        text-decoration: none;
    }

    h1 {
        font-family: var(--primary-font-family);
        color: var(--ion-color-primary);
        font-weight: bold;
        font-size: 2rem;
        @media screen and (max-width:1024px) {
            font-size: 1.5rem;
        }
    }

    hr {
        border: 2px solid var(--ion-color-primary);
        height: 0;
        margin: 0;
        width: 100px;
    }

    p {
        font-family: var(--secondary-font-family);
        text-align: justify;
    }

    .banner {
        position: relative;

        .title {
            position: absolute;
            top: 10%;
            left: 54%;
            color: var(--ion-color-link);
            width: 35%;
            @media screen and (max-width:425px) {
                top: 2%;
                width: 41%;
            }

            h1 {
                color: var(--ion-color-link);
                @media screen and (max-width:425px) {
                    font-size: 1.3rem;
                    margin: 5px 0;
                }
            }

            hr {
                border: 2px solid var(--ion-color-link);
            }

            p {
                font-size: 2rem;
                text-align: right;

                @media screen and (max-width:1440px) {
                    font-size: 1.5rem;
                }
                @media screen and (max-width:1024px) {
                    font-size: 1rem;
                }
                @media screen and (max-width:425px) {
                    font-size: .7rem;
                    margin: 5px 0;
                }
            }
        }
    }

    .text-center hr {
        margin: 0 auto;
    }

    .text-end {
        hr {
            margin: 0 0 0 auto;
        }

        p {
            text-align: right;
        }
    }


    ion-col {
        padding: 30px;

        @media screen and (max-width:1024px) {
            padding: 20px;
        }
    }
}
