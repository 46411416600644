.tab{
    .bar{
        display: flex;
        list-style: none;
        li{
            cursor: pointer;
            padding: 10px 25px;
            font-weight: 600;
            font-size: 0.9rem;
            text-transform: uppercase;
            &.active{
                color: var(--ion-color-link);
                background: #efefef;
                border-bottom: 2px solid;
            }
        }
        margin: 0;
        padding: 0;
    }
    .content{
        padding: 20px;
    }
}