#about-page {
    padding: 20px 0 50px;

    h1 {
        font-family: var(--primary-font-family);
        color: var(--ion-color-primary);
        font-weight: bold;
        font-size: 40px;
    }

    hr {
        border: 2px solid var(--ion-color-primary);
        height: 0;
        margin: 0;
        width: 100px;
    }

    .text-center hr {
        margin: 0 auto;
    }

    p {
        font-family: var(--secondary-font-family);
        text-align: justify;
    }

    ion-col {
        padding: 30px;
        @media screen and (max-width:1024px) {
            padding: 20px;
        }
    }

    .about {
        border-left: 3px solid var(--ion-color-primary);

        hr {
            width: 60%;
        }

        h1,
        p {
            padding-left: 20px;
        }

        h1 {
            padding-top: 20px;
        }

        p {
            padding-bottom: 20px;
        }
    }
}
