.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
    margin-right: 10px;

    li {
        a {
            display: block;
            padding: 10px 20px;
            background: var(--ion-color-link);
            color: white;
            cursor: pointer;
            margin-bottom: 6px;
            margin-left: 10px;

            &:hover {
                color: var(--ion-color-link);
                background: white;
                box-shadow: 0px 0px 4px 0px black inset;
            }
        }

        &.selected {
            a {
                color: var(--ion-color-link);
                background: white;
                box-shadow: 0px 0px 4px 0px black inset;
            }
        }
    }
}