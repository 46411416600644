@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-product {
    margin: 0;
    .img,.title,.price,.sold {
        animation: skeleton-loading 1s linear infinite alternate;
    }
    .img,.title {
        width: 100%;
        margin-bottom: 10px;
    }
    .img {
        height: 200px;
    }
    .title {
        height: 2rem;
    }
    .info {
        display: flex;
        justify-content: space-between;
    }
    .price {
        width: 30%;
        height: 1rem;
    }
    .sold {
        width: 35%;
        height: 1rem;
    }
}