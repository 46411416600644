figure.zoomable {
    background-repeat: no-repeat;
    background-size: 0%;
    margin: 0;

    @media screen and (min-width:769px) {
        &:hover {
            background-size: 150%;
            img {
                opacity: 0;
            }
        }
    }
    @media screen and (max-width:768px) {
        background: none !important;
    }
}
