#invoice {
    strong.title{
        width: 75px;
        display:  inline-block;
    }
    max-width: 900px;
    margin: 0 auto;
    .space{
        margin-bottom: 100px;
    }

    table,
    td,
    th {
        border: 1px solid var(--ion-color-light-shade);
        border-collapse: collapse;
    }

    table {
        width: 100%;
    }

    td{
        padding: 16px;
        &.has-img{
            width: 74px;
            padding: 5px;
            border-left: none;
            text-align: center;
            img{
                width: 50px;
                padding: 3px 0 0;
            }
        }
    }

    th {
        padding: 16px;
        background-color: var(--ion-color-light-shade);
    }
    .items{
        thead th{
            text-align: center;
        }
        tfoot th{
            text-align: right;
        }
        .info {
            .row{
                display: flex;
                flex-wrap: wrap;
                margin: 0 0 5px;
                .title{
                    font-weight: bold;
                    width: 130px;
                    display: inline-block;
                }
            }
        }
    }
}
