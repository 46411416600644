#cart-total{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    div{
        margin: 0 10px;
        span{
            white-space: nowrap;
        }
        &:nth-child(2){
            text-align: center;
        }
        &:last-child{
            text-align: right;
        }
    }
}
