footer {
    background: #efefef;
    color: var(--ion-color-primary);
    position: relative;
    z-index: 2;


    .about {
        padding: 50px 0;

        hr {
            color: var(--ion-color-dark);
            border: 1px solid;
            height: 0;
            width: 100px;
            margin: 10px 0 20px 0;
        }

        h1 {
            color: var(--ion-color-dark);
        }

        .info {
            color: var(--ion-color-link);
        }

        .item {
            margin-bottom: 10px;
            font-size: 18px;
            color: var(--ion-color-link);

            a {
                text-decoration: none;
            }

            ion-icon {
                margin-right: 10px;
            }

            address,
            a {
                color: var(--ion-color-link);

                &:hover {
                    color: var(--ion-color-primary);
                    text-decoration: underline;
                    margin-left: 10px;
                }
            }
        }

        .links {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            a {
                margin-bottom: 10px;
                color: var(--ion-color-link);
                text-decoration: none;

                &:hover {
                    color: var(--ion-color-primary);
                    text-decoration: underline;
                    margin-left: 10px;
                }
            }
        }
    }

    .copyright {
        text-align: center;
        color: var(--ion-color-link);
        padding: 20px 0;
        background: white;
        border-top: 3px solid;
    }
}

.btn-install-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
}

.btn-install {
    display: flex;
    align-items: center;
    border: 2px solid #999;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: max-content;
    text-decoration: none;
    background: #fff;

    .text {
        padding: 0 10px;
        text-align: center;
    }

    h1,
    h2 {
        margin: 0;
    }

    h1 {
        font-weight: bold;
    }

    h2 {
        font-size: 15px;
        letter-spacing: 1;
    }

    >svg {
        width: auto;
        height: 50px;
    }
}