#bottom-menu {
    padding: 5px 0;
    position: fixed;
    bottom: 0;
    background: #eee;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.75);
    z-index: 1;
}